import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain="uniscrow.eu.auth0.com"
      clientId="dJn7E7fDuRSYabj8N5rNs3o8ZPvr9MJS"
      redirectUri={window.location.origin}
      audience="uniscrow-bubble-app-client"
      onRedirectCallback={(state) => {
        if (state) {
          navigate(state.targetUrl);
        }
      }}
    >
      {children}
    </Auth0Provider>
  );
};
export default Auth0ProviderWithNavigate;
