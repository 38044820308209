import { ethers } from "ethers";
const tokenAddr = "0x80b5de149D8d3b2EFe4aE5b3c74Dd3c0c24882D0";
const uniscrowAddr = "0x8387A9f497353af8a54269824f2272195C3C2A0f";
const tokenAbi = ["function approve(address to, uint amount) returns (bool)"];
const chainId = 4; //rinkeby
const bn = ethers.BigNumber.from;
const allowance = bn(300000).mul(bn(10).pow(bn(18)));

export const verifyAddress = async (
  wallet: ethers.Wallet
) => {
  try {
    ethers.Wallet.fromMnemonic(wallet.mnemonic.phrase.trim());
  } catch (err) {
    alert(err);
    return
  }
  let tokenContract = new ethers.Contract(tokenAddr, tokenAbi, wallet);
  let rawTx = await tokenContract.populateTransaction.approve(
    uniscrowAddr,
    allowance
  );
  rawTx = {
    ...rawTx,
    nonce: 0,
    chainId: chainId,
    gasLimit: bn(100000),
    gasPrice: ethers.utils.parseUnits("2", "gwei"),
    value: bn(0),
    type: 2,
    maxFeePerGas: ethers.utils.parseUnits("2", "gwei"),
    maxPriorityFeePerGas: ethers.utils.parseUnits("2", "gwei"),
  };

  const signedTx = await wallet.signTransaction(rawTx);
  // txHash
  return ethers.utils.keccak256(signedTx);

  // TODO: send to AWS Proxy
  //window.open(callbackUrl + "?tx=" + sig + "&address=" + address);
};
export const createRandomAddress = () => ethers.Wallet.createRandom();
