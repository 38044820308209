import { useSelector } from "react-redux";
import { selectors as uniscrowSelectors } from "../store/slices/uniscrow";
import UniscrowLogo from "../components/Logo";
type Props = {
  children: React.ReactNode;
};
export const Fill = ({ children }: Props) => {
  const width = useSelector(uniscrowSelectors.clientWidth);
  return (
    <div className="d-flex flex-column justify-content-between vh-100 bg-dimblue p-5">
      <div className="d-flex justify-content-center">
        {width >= 1000 && (
          <section className="d-flex flex-column justify-content-between">
            <UniscrowLogo height={60} width={176} />
            <img
              style={{ minWidth: 156 }}
              className="w-75 animated-svg-load"
              src={`/svg/communicate.svg`}
              alt="svg"
            />
            <div />
          </section>
        )}
        <section className="d-flex flex-column justify-content-between">
          {width < 1000 && (
            <div>
              <UniscrowLogo height={60} width={176} />
            </div>
          )}
          {children}
        </section>
        {width >= 1000 && (
          <section className="d-flex flex-column justify-content-between">
            <div style={{ height: 92 }} />

            <img
              style={{ minWidth: 156 }}
              className="w-75 animated-svg-load"
              src={`/svg/throw.svg`}
              alt="svg"
            />
            <div />
          </section>
        )}
      </div>
      <div
        className="w-100"
        style={{
          backgroundImage: "url(/images/sponsors.png)",
          height: 80,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

export default Fill;
