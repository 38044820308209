import { Button } from "react-bootstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as uniscrowActions,
  selectors as uniscrowSelectors,
} from "../store/slices/uniscrow";
export const LogoutButton = () => {
  const width = useSelector(uniscrowSelectors.clientWidth);
  let path = window.location.pathname.split("/");
  path.splice(-1);
  const { logout, user } = useAuth0();
  const dispatch = useDispatch();
  return (
    <Button
      className="position-fixed end-0 align-items-center justify-content-center gap-1 m-2 d-flex"
      onClick={() => {
        logout({ returnTo: window.location.origin });
        dispatch(uniscrowActions.setAccessTokenRequested(false));
        dispatch(uniscrowActions.setAccessToken(""));
      }}
      variant="white"
    >
      <img
        style={{
          width: 30,
          height: 30,
          objectFit: "cover",
          borderRadius: "50%",
        }}
        src={user && user.picture}
        referrerPolicy="no-referrer"
        alt="user"
      />{" "}
      {width > 500 && <small>{user && user.name}</small>}{" "}
      <i className="bi bi-box-arrow-right" />
    </Button>
  );
};

export default LogoutButton;
