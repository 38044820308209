import { ethers } from "ethers";
const AWS_URL = "https://v4vqtgfa6i.execute-api.eu-west-1.amazonaws.com/dev/";

/** Returns the balance as formatted ETH */
const getEtherscanBalance = async (
  network: string,
  address: string,
  tokenAddress: string
) => {
  const ETHERSCAN_API_KEY = "XN4HD1YJWASTNSNVNZ58MSK33SDTSFB5FE";
  const { result: balance } = await fetch(
    `https://api-${network}.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${address}&tag=latest&apikey=${ETHERSCAN_API_KEY}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then((resp) => resp.json());
  const ethValue = ethers.utils.formatEther(balance /* WEI */);
  return ethValue;
};

export const getAccount = async (account: string, accessToken: string) => {
  const result = await fetch(AWS_URL + "obj/Account/" + account, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((resp) => resp.json());
  console.log("/Account object: ", result);
  return result;
};

export const getProfile = async (accessToken: string) => {
  const result = await fetch(AWS_URL + "obj/Profile", {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((resp) => resp.json());
  console.log("/Profile object: ", result[0]);
  return result[0];
};

/** Returns the balance of the user in the currency requested by the escrow */
export const getBalance = async (escrow: string, accessToken: string) => {
  // Getting Profile from email
  const profile = await getProfile(accessToken);
  if (!profile || !profile["wallet_address"]) {
    // Getting Profile from email
    return {
      result: "error",
      message: "Incomplete registration!",
      data: { amount: 0 },
    };
  }
  const account = await getAccount(escrow, accessToken);

  const balance = await getEtherscanBalance(
    account["Network"],
    profile["wallet_address"],
    account["Token Address"]
  );
  return {
    result: "success",
    message: `${balance} ${account["currency"]}`,
    data: { amount: Number(balance) },
  };
};
const setCoOwner = async (accountId: string, accessToken: string) => {
  const resp = await fetch(AWS_URL + "wf/set-co-owner", {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ accountId }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};
export const registerApproveWallet = async (
  signedTransactionData: string,
  address: string,
  accessToken: string
) => {
  const resp = await fetch(AWS_URL + "wf/register-approve-wallet", {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ addr: address, txdata: signedTransactionData }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};

const createDeposit = async (
  escrowAccount: string,
  amount: string,
  accessToken: string
) => {
  const resp = await fetch(AWS_URL + "wf/create-deposit-request", {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ Escrow: escrowAccount, Amount: amount }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};
export const setCoOwnerAndCreateDeposit = async (
  accoundId: string,
  amount: string,
  accessToken: string
) => {
  const setCoOwnerRes = await setCoOwner(accoundId, accessToken);
  const userProfile = await getProfile(accessToken);
  if (!userProfile) {
    return console.warn("Couldn't load profile");
  }
  const createDepositRes = await createDeposit(accoundId, amount, accessToken);
  console.log(setCoOwnerRes, createDepositRes);
  return true;
};
