import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../layout/Default";
import { getAccount, getProfile } from "../api/awsProxy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors as uniscrowSelectors } from "../store/slices/uniscrow";
import { CopyButton } from "../components/CopyButton";
function shorten(tokenAddr: string) {
  return (
    tokenAddr.slice(0, 5) +
    "..." +
    tokenAddr.slice(tokenAddr.length - 5, tokenAddr.length - 1)
  );
}
export const SepaInstructions = () => {
  const navigate = useNavigate();
  const iban = "IT15Y0300203280821477566589";
  const { escrow } = useParams();
  const [currency, setCurrency] = useState("");
  const [userBlockchainAddress, setUserBlockchainAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const accessToken = useSelector(uniscrowSelectors.accessToken);
  useEffect(() => {
    if (!accessToken) {
      return;
    }
    getProfile(accessToken).then((result) => {
      setUserBlockchainAddress(result["wallet_address"]);
    });
    getAccount(escrow!, accessToken).then((result) => {
      setCurrency(result.currency);
      setTokenAddress(result["Token Address"]);
    });
  }, [escrow, accessToken]);
  return (
    <DefaultLayout svg="think">
      <div
        className="d-flex p-3 h-100 pb-5"
        style={{ width: "min-content", minWidth: 360 }}
      >
        <div className="me-4 d-flex flex-column justify-content-between">
          <div>
            <h1 className="text-darkblue fw-bold text-left mb-5">
              Instructions SEPA
            </h1>
            <section className="d-flex flex-column">
              <br />
              <span>
                Your SEPA deposit will be converted into <b>{currency}</b> and will
                refill your <b>wallet address</b> (
                {userBlockchainAddress ? (
                  <CopyButton longString={userBlockchainAddress}>
                    {shorten(userBlockchainAddress)}
                  </CopyButton>
                ) : (
                  <Spinner animation="border" size="sm" />
                )}
                )
              </span>
              <br />
              <small className="text-muted text-nowrap">
              <b>Token address</b>{" "}
                {tokenAddress ? (
                  <CopyButton longString={tokenAddress}>
                    {shorten(tokenAddress)}
                  </CopyButton>
                ) : (
                  <Spinner animation="border" size="sm" />
                )}
              </small>
              <br />
              <span>IBAN:</span>
              <b className="ms-3">
                {" "}
                <small></small>
                {iban}
              </b>
              <span>Reason:</span>
              <b className="ms-3">
                {escrow && escrow.slice(escrow.length - 7, escrow.length - 1)}
              </b>
            </section>
          </div>
          <div className="d-grid mb-5">
            <Button
              className="my-2 p-2"
              /* To change */ onClick={() => navigate("../confirmation")}
            >
              <i className="bi bi-arrow-left" /> BACK
            </Button>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SepaInstructions;
