import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { formatter } from "../utils/numberFormatter";
import { getBalance, setCoOwnerAndCreateDeposit } from "../api/awsProxy";
import { useAuth0 } from "@auth0/auth0-react";
import PurchaseButton from "../components/PurchaseButton";
import DefaultLayout from "../layout/Default";
import { useSelector } from "react-redux";
import { selectors as uniscrowSelectors } from "../store/slices/uniscrow";

export const Confirmation = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [balance, setBalance] = useState({
    result: "",
    message: "",
    data: { amount: 0 },
  });
  const [isSendingData, setIsSendingData] = useState(false);
  const { escrow, dueAmount, callback } = useParams();
  const accessToken = useSelector(uniscrowSelectors.accessToken);
  useEffect(() => {
    if (!accessToken || !user || !user.email) {
      return;
    }
    getBalance(escrow!, accessToken)
      .then((resp) => {
        console.log(resp);
        setBalance(resp);
      })
      .catch((e) => navigate("../no-account"));
  }, [user, escrow, accessToken, navigate]);
  return (
    <DefaultLayout svg="communicate">
      <div className="d-flex p-3 h-100">
        <div className="me-4 d-flex flex-column justify-content-between">
          <div>
            <div>Due amount:</div>
            <h1 className="d-inline text-darkblue fw-bold text-left">
              &emsp;{formatter.format(Number(dueAmount))}
            </h1>
            <div className="d-flex justify-content-end">
              <PurchaseButton url={callback!} className="d-block my-4" />
            </div>
            <section>
              <div className="text-nowrap">
                Your balance:
                <br />
                &emsp;{" "}
                {balance.message ? (
                  <b className="fs-4">{balance.message}</b>
                ) : (
                  <Spinner animation="border" size="sm" />
                )}
              </div>
            </section>
          </div>
          <fieldset disabled={isSendingData} className="d-grid mb-5 w-100">
            <div
              className="d-block my-2 "
              title={
                balance.result === "error"
                  ? "Complete your registration to proceed"
                  : balance.data.amount < Number(dueAmount)
                  ? "Not enough balance"
                  : undefined
              }
            >
              <Button
                className="p-2 w-100"
                disabled={
                  balance.message === "error" ||
                  balance.data.amount < Number(dueAmount)
                }
                onClick={() => {
                  setIsSendingData(true);
                  setCoOwnerAndCreateDeposit(
                    escrow!,
                    dueAmount!,
                    accessToken
                  ).then(() => {
                    navigate("../success");
                    setIsSendingData(false);
                  });
                }}
              >
                {isSendingData ? (
                  <span>
                    <Spinner animation="border" size="sm" /> PROCESSING
                  </span>
                ) : (
                  "PAY NOW"
                )}
              </Button>
            </div>
            <Button
              className="my-2 p-2"
              variant="transparent"
              onClick={() => navigate(`../instructions-sepa`)}
            >
              TOP UP WITH SEPA
            </Button>
          </fieldset>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Confirmation;
