import API_KEY from "./API_KEY.json";
const dynamoURL = "https://v4vqtgfa6i.execute-api.eu-west-1.amazonaws.com/dev/";

export const getDynamoProfile = async (accessToken: string) => {
  const result = await fetch(dynamoURL + "profile", {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": `application/json`,
    },
  }).then((resp) => resp.json());
  console.log("/profile :", result.response);
  return result.response;
};
export type Profile = {
  first_name: string;
  family_name: string;
  country_of_residence: string;
  home_address: string;
  iban: string;
  blockchain_address: string;
};
export const createDynamoProfile = async (
  data: Profile,
  accessToken: string
) => {
  const result = await fetch(dynamoURL + "profile/create", {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ arguments: data }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": `application/json`,
    },
  }).then((resp) => resp.json());
  console.log("/profile/create :", result);

  const submit = await fetch(dynamoURL + "profile/submit", {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": `application/json`,
    },
  }).then((resp) => resp.json());
  console.log("/profile/submit :", submit);

  return { result, submit };
};
export const createTempUser = async (email: string, walletAddr: string) => {
  const body = new URLSearchParams({
    email,
    walletAddr,
  });
  const result = await fetch(
    "https://beta.uniscrow.com/version-test/api/1.1/wf/temp-user-data",
    {
      method: "POST",
      mode: "cors",
      body: body,
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": `application/x-www-form-urlencoded`,
      },
    }
  ).then((resp) => resp.json());
  return result
};
