import DefaultLayout from "../layout/Default";
export const NotFound = () => {
  return (
    <DefaultLayout svg="think">
      <div className="p-3 m-3" style={{ maxWidth: 350 }}>
        <h1 className="text-darkblue fw-bold text-left">404 - Not Found</h1>
        <div style={{ minWidth: 280 }}>
          The requested URL path doesn't exist
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NotFound;
