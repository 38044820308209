import UniscrowLogo from "../components/Logo";
import { useSelector } from "react-redux";
import { selectors as uniscrowSelectors } from "../store/slices/uniscrow";
type Props = {
  svg?: string;
  children: React.ReactNode;
};
export const Default = ({ svg = "payment", children }: Props) => {
  const width = useSelector(uniscrowSelectors.clientWidth);
  return (
    <div className="d-flex">
      <section
        style={{ width: "min-content", maxWidth: "max-content" }}
        className={`d-flex flex-column m-auto align-items-start bg-white flex-grow-1 px-3 min-vh-100 py-2 ${
          width >= 800 ? "shadow-sm" : ""
        }`}
      >
        <UniscrowLogo height={60} width={176} />
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <main className="h-100">{children}</main>

          <div
            className="w-100"
            style={{
              backgroundImage: "url(/images/sponsors.png)",
              height: 80,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>
      </section>
      {width >= 800 && (
        <section
          style={{ zIndex: -100 }}
          className="d-flex justify-content-center bg-dimblue flex-grow-1 p-5"
        >
          <img
            className="w-75 h-75 animated-svg-load"
            src={`/svg/${svg}.svg`}
            alt="svg"
          />
        </section>
      )}
    </div>
  );
};

export default Default;
