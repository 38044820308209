import { Routes, Route, Navigate } from "react-router-dom";
import { Loading } from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as uniscrowActions,
  selectors as uniscrowSelectors,
} from "./store/slices/uniscrow";
import { getProfile } from "./api/awsProxy";
import "./styles/App.scss";
import { getDynamoProfile } from "./api/dynamoDb";
import * as Pages from "./pages";
import * as Wrappers from "./components/wrappers";
export const App = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
    logout,
  } = useAuth0();

  const [isReady, setIsReady] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isKycVerified, setIsKycVerified] = useState(false);
  const [isExistingProfile, setIsExistingProfile] = useState(false);

  const dispatch = useDispatch();
  window.onresize = () => {
    dispatch(uniscrowActions.setClientWidth(window.innerWidth));
  };
  const accessTokenRequested = useSelector(
    uniscrowSelectors.accessTokenRequested
  );
  useEffect(() => {
    async function getAccessToken() {
      const token = await getAccessTokenSilently({
        audience: `uniscrow-bubble-app-client`,
      });
      console.log("Got bearer token");
      return token;
    }
    // Logging JWT access token
    if (!isAuthenticated && !isLoading) {
      setIsReady(true);
      return;
    }
    if (accessTokenRequested) {
      return;
    }
    if (isAuthenticated) {
      setIsEmailVerified(!!user?.email_verified);
      dispatch(uniscrowActions.setAccessTokenRequested(true));
      getAccessToken()
        .then((res) => {
          dispatch(uniscrowActions.setAccessToken(res));
          return res;
        })
        .then((accessToken) => {
          Promise.all([
            getDynamoProfile(accessToken),
            getProfile(accessToken),
          ]).then(([dynamoProfile, profile]) => {
            console.log(dynamoProfile);
            console.log(!!dynamoProfile);
            setIsExistingProfile(!!dynamoProfile);
            if (profile) {
              setIsKycVerified(!!profile["verified"]);
              setIsRegistered(!!profile["wallet_address"].length);
              setIsReady(true);
            } else {
              setIsRegistered(false);
              setIsReady(true);
            }
          });
        });
    }
  }, [
    isLoading,
    accessTokenRequested,
    dispatch,
    getAccessTokenSilently,
    isAuthenticated,
    user?.email_verified,
  ]);

  if (isLoading || !isReady) return <Loading />;

  const signProps = { isAuthenticated, loginWithRedirect, logout, user };
  console.log({
    isAuthenticated,
    isEmailVerified,
    isRegistered,
    isKycVerified,
    isExistingProfile,
  });
  const parameters = "/:escrow/:dueAmount/:callback";

  return (
    <Routes>
      <Route path="login" element={<Pages.Login {...signProps} />} />
      <Route path="signup" element={<Pages.Signup {...signProps} />} />
      {/* Params */}
      <Route index element={<Navigate to={`/registration-completed`} />} />

      <Route path={parameters}>
        <Route path="login" element={<Pages.Login {...signProps} />} />
        <Route path="signup" element={<Pages.Signup {...signProps} />} />
        <Route path="" element={<Navigate to="confirmation" />} />

        <Route element={<Wrappers.Auth isAuthenticated={isAuthenticated} />}>
        <Route path="wait-approval" element={<Pages.WaitApproval />} />
          <Route
            path="complete-registration"
            element={
              isExistingProfile ? (
                isRegistered ? (
                  <Navigate to="/registration-completed" />
                ) : (
                  <Navigate replace to="/wait-approval" />
                )
              ) : (
                <Pages.CompleteRegistration />
              )
            }
          />
          <Route
            element={
              <Wrappers.ExistingProfile
                existing={isExistingProfile && !isRegistered}
              />
            }
          >
            <Route
              element={<Wrappers.Registered isRegistered={isRegistered} />}
            >
              <Route path="verify-email" element={<Pages.VerifyEmail />} />
              <Route
                element={
                  <Wrappers.VerifiedEmail isVerified={isEmailVerified} />
                }
              >
                <Route
                  element={<Wrappers.VerifiedKyc isVerified={isKycVerified} />}
                >
                  <Route path="confirmation" element={<Pages.Confirmation />} />
                  <Route
                    path="registration-completed"
                    element={<Pages.RegistrationCompleted />}
                  />
                  <Route
                    path="instructions-sepa"
                    element={<Pages.SepaInstructions />}
                  />
                  <Route path="success" element={<Pages.Success />} />
                  <Route path="no-account" element={<Pages.NoAccount />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="login" element={<Pages.Login {...signProps} />} />
        <Route path="signup" element={<Pages.Signup {...signProps} />} />
      </Route>

      {/* No params */}

      <Route element={<Wrappers.Auth isAuthenticated={isAuthenticated} />}>
        <Route path="wait-approval" element={<Pages.WaitApproval />} />
        <Route
          element={
            <Wrappers.ExistingProfile
              existing={isExistingProfile && !isRegistered}
            />
          }
        >
          <Route
            path="complete-registration"
            element={
              isRegistered ? (
                <Navigate to="registration-completed" />
              ) : (
                <Pages.CompleteRegistration />
              )
            }
          />
        </Route>
        <Route element={<Wrappers.Registered isRegistered={isRegistered} />}>
          <Route path="verify-email" element={<Pages.VerifyEmail />} />
          <Route
            element={<Wrappers.VerifiedEmail isVerified={isEmailVerified} />}
          >
            <Route
              path="wait-verification"
              element={<Pages.WaitVerification />}
            />
            <Route
              element={<Wrappers.VerifiedKyc isVerified={isKycVerified} />}
            >
              <Route
                path="registration-completed"
                element={<Pages.RegistrationCompleted />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Pages.NotFound />} />
    </Routes>
  );
};

export default App;
