import DefaultLayout from "../layout/Default";
export const WaitApproval = () => {
  return (
    <DefaultLayout svg="throw">
      <div className="p-3 m-3" style={{ maxWidth: 350 }}>
        <h1 className="text-darkblue fw-bold text-left">
          Waiting for account approval
        </h1>
        <div style={{ minWidth: 280 }}>
          Your current account is registered and waiting for backoffice approval.
        </div>
      </div>
    </DefaultLayout>
  );
};

export default WaitApproval;
