import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FillLayout from "../layout/Fill";

export const Success = () => {
  const { callback } = useParams();
  return (
    <FillLayout>
      <div
        style={{ maxWidth: 900 }}
        className="d-flex flex-column p-3 m-3 h-100 pb-5"
      >
        <div className="text-center">
          <h1 className="text-darkblue fw-bold text-center mb-5">Success</h1>
          <section className="mb-5">
            Your deposit will be processed shortly.
            <br />
            <br />
            Once the funds are secured in the escrow the seller will be informed
            and they will be shipping the goods or services related to the
            transaction.
          </section>
          <section className="mb-5">
            Funds stay secure in the escrow until you authorize the final
            payment to the seller
          </section>
          <div className="d-grid mb-5">
            <a href={callback!} rel="noreferrer">
              <Button className="my-2 mx-5 p-2" variant="pastelbg">
                <small>RETURN TO WEBSITE</small>
              </Button>
            </a>
          </div>
          <span className="text-muted">
            Lorem ipsum dolor sit amet, consetetur adipiscing elit.
          </span>
          <br />
          <a href="https://www.uniscrow.com/" rel="noreferrer" target="_blank">
            www.uniscrow.com
          </a>
        </div>
      </div>
    </FillLayout>
  );
};

export default Success;
