import { Modal, Button, ModalProps, Form, Spinner } from "react-bootstrap";
import FormInput from "../FormInput";
import { verifyAddress, createRandomAddress } from "../../utils/ethers";
import { useState } from "react";
import { selectors as uniscrowSelectors } from "../../store/slices/uniscrow";
import { useSelector } from "react-redux";
import { registerApproveWallet } from "../../api/awsProxy";
import { CopyButton } from "../CopyButton";
const wallet = createRandomAddress();

export const GenerateAddress = ({
  setAddressInPostData,
  setShow,
  callback,
  ...props
}: ModalProps) => {
  const hiddenText =
    "***** ******* **** ***** ******* **** ***** ******* **** ***** ******* ****";
  const [hideMnemo, setHideMnemo] = useState(false);
  const [inputMnemo, setInputMnemo] = useState("");
  const [loadingVerification, setLoadingVerification] = useState(false);
  const accessToken = useSelector(uniscrowSelectors.accessToken);

  return (
    <Modal {...props} onHide={() => setShow(false)} onShow={() => {}}>
      <Modal.Header closeButton>
        <Modal.Title>Generate your Ethereum wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5">
        <div className="mb-3 text-muted">
          Address: <i>{wallet.address}</i>
        </div>
        <span className="fs-5 text-muted">
          Copy the phrase below(<b className="">your wallet seed</b>) and store
          it in a safe place
        </span>
        <div className="fw-bolder fst-italic text-center text-shockblue fs-2  p-2 my-2">
          <span style={{ userSelect: "none" }}>“</span>
          {hideMnemo ? hiddenText : wallet.mnemonic.phrase}
          <span style={{ userSelect: "none" }}>”</span>
        </div>
        <div className="py-3">
          <CopyButton longString={wallet.mnemonic.phrase} disabled={hideMnemo}>
            Copy seed
          </CopyButton>
        </div>
        <div className="d-flex gap-1 align-items-center m-3">
          <Form.Check
            type="checkbox"
            onChange={(e) => setHideMnemo(e.target.checked)}
          />
          <i className="mt-1">Done, I've stored the phrase in a safe place</i>
        </div>

        {hideMnemo && (
          <div className="mt-4">
            <span className="fs-5 text-muted">
              Write down your 12 words phrase
            </span>
            <FormInput
              onChange={(e: any) => {
                setInputMnemo(e.target.value);
              }}
              nativeProps={{ type: "text" }}
            />
            <div className="mt-2 d-flex justify-content-end">
              <Button
                disabled={inputMnemo !== wallet.mnemonic.phrase}
                variant="transparent"
                onClick={async () => {
                  setLoadingVerification(true);
                  const txHash = await verifyAddress(wallet);
                  if (!txHash) {
                    return;
                  }
                  try {
                    const res = await registerApproveWallet(
                      txHash,
                      wallet.address,
                      accessToken
                    );
                    console.log(res);
                    callback(wallet.address);
                    setInputMnemo("");
                    setHideMnemo(false);
                    setLoadingVerification(false);
                    setShow(false);
                  } catch {
                    console.warn("Error while verifying address");
                  }
                }}
              >
                {loadingVerification ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Check and verify"
                )}
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenerateAddress;
