import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import { configureStore } from "./store";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/index.scss";

const store = configureStore({});
export { store };
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </Provider>
);
// reportWebVitals();
