import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";

export const CopyButton = ({
  longString,
  children,
  disabled=false
}: {
  longString: string;
  children: React.ReactNode;
  disabled?:boolean;
}) => {
  const renderTooltip = ({ ...props }) => (
    <Tooltip id="button-tooltip" {...props}>
      Copied!
    </Tooltip>
  );
  const [show, setShow] = useState(false);
  return (
    <OverlayTrigger placement="top" overlay={renderTooltip} show={show}>
      <Button
        variant="transparent"
        className=" d-inline-flex align-items-center gap-2 p-1 fw-normal  mx-1"
        disabled={disabled}
        onClick={() => {
          navigator.clipboard.writeText(longString);
          setShow(true);
          setTimeout(() => setShow(false), 1000);
        }}
      >
        {children}
        <i className="bi bi-clipboard" />
      </Button>
    </OverlayTrigger>
  );
};
export default CopyButton;
