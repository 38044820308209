import DefaultLayout from "../layout/Default";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions as uniscrowActions } from "../store/slices/uniscrow";
import { useEffect } from "react";
export const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { user, logout, loginWithRedirect } = useAuth0();
  useEffect(() => {}, []);
  return (
    <DefaultLayout svg="think">
      <div className="p-3 m-3" style={{ maxWidth: 400 }}>
        <h1 className="text-darkblue fw-bold text-left">Email verification</h1>
        <div style={{ minWidth: 360 }}>
          Please click the link we've sent at <b><i>{user?.email}</i></b> to validate your
          Auth0 registration. <br /><br />A new login is required to apply the
          changes and to complete your registration.
        </div>
        <div className="divider my-5" />
        <Button
          className="w-100"
          onClick={() => {
            logout();
            dispatch(uniscrowActions.setAccessTokenRequested(false));
            dispatch(uniscrowActions.setAccessToken(""));
            loginWithRedirect({ screen_hint: "login" });
          }}
        >
          LOGIN
        </Button>
      </div>
    </DefaultLayout>
  );
};
export default VerifyEmail;
