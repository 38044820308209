import { Button } from "react-bootstrap";
import { useState } from "react";
export const PurchaseButton = ({ url = "", ...props }) => {
  const [showIcon, setShowIcon] = useState(true);
  return (
    <a href={url} rel="noreferrer" {...props}>
      <Button
        variant="white"
        className="shadow-sm d-flex align-items-center gap-2"
      >
        {showIcon && (
          <img
            height={32}
            width={32}
            referrerPolicy="no-referrer"
            alt="vendor"
            src={`https://www.google.com/s2/favicons?sz=64&domain=${fetchDomain(
              url
            )}`}
            onError={({currentTarget}) => {
              // If google API doesn't have the favicon it tries to fetch it directly
              const currentSrc=currentTarget.src;
              let imgUrl:URL;
              try{
                imgUrl = new URL(url);
              } catch{
                setShowIcon(false);
                return;
              }
              const faviconUrl = imgUrl.origin + "/favicon.ico";
              if(currentSrc===faviconUrl){
                // If the error was triggered by the fallback(direct favicon fetch), omits the icon
                setShowIcon(false);
              }else{
                // Otherwise it tries with the fallback
                currentTarget.src = faviconUrl;
              }
            }}
          />
        )}
        Your current purchase <small className="bi bi-box-arrow-up-right" />
      </Button>
    </a>
  );
};
export default PurchaseButton;
const fetchDomain = (url: string) => {
  let splitArray = url.split("/");
  for (let i = 0; i < splitArray.length; i++) {
    if (splitArray[i].includes(".")) {
      return "https://" + splitArray[i];
    }
  }
  return "";
};
