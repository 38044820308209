import { Navigate, Outlet } from "react-router-dom";
import LogoutButton from "../LogoutButton";

export const Auth = ({ isAuthenticated = false }) => {
  return isAuthenticated ? (
    <div>
      <LogoutButton />
      <Outlet />
    </div>
  ) : (
    <Navigate replace to={`./login`} />
  );
};
export default Auth;
