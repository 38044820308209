import DefaultLayout from "../layout/Default";
export const NoAccount = () => {
  return (
    <DefaultLayout svg="think">
      <div className="p-3 m-3" style={{ maxWidth: 350 }}>
        <h1 className="text-darkblue fw-bold text-left">
          This escrow account doesn't exist
        </h1>
        <div style={{ minWidth: 280 }}>
          The escrow account for your item does not exist. Maybe there is a typo
          somewhere or the account has been deleted by our side.
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NoAccount;
