import { Button } from "react-bootstrap";
import FillLayout from "../layout/Fill";

export const RegistrationCompleted = () => {
  return (
    <FillLayout>
      <div
        style={{ maxWidth: 900 }}
        className="d-flex flex-column p-3 m-3"
      >
        <div className="text-center">
          <h2 className="text-darkblue fw-bold text-center mb-5">
            Your account is registered and ready to use!
          </h2>
          <section className="mb-5">
            You can now trade goods, services, crypto assets and more with the
            security of escrows.
          </section>
          <div className="d-grid mb-5">
            <a href="https://www.uniscrow.com/" rel="noreferrer">
              <Button className="my-2 mx-5 p-2" variant="pastelbg">
                Explore uniscrow
              </Button>
            </a>
          </div>
          <span className="text-muted">
            The safest way to buy or sell anything online.
          </span>
          <br />
          <a href="https://www.uniscrow.com/" rel="noreferrer" target="_blank">
            www.uniscrow.com
          </a>
        </div>
      </div>
    </FillLayout>
  );
};

export default RegistrationCompleted;
