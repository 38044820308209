import { Form } from "react-bootstrap";
import { useState } from "react";
type Props = {
  content?: JSX.Element | string;
  onChange?: Function;
  nativeProps?: any;
  width?: number | string;
};
/* TODO: Validate URLs */
export const FormInput = ({
  nativeProps = { type: "text", required: true },
  content,
  onChange = () => {},
  width,
}: Props) => {
  if (!nativeProps.name) {
    nativeProps.name = nativeProps.id;
  }
  const [valid, setValid] = useState(false);
  return (
    <div className="d-flex flex-column" style={{ width: width || "auto" }}>
      {nativeProps.title && (
        <label htmlFor={nativeProps.id}>
          <small>{nativeProps.title}</small>
        </label>
      )}

      <div
        className={`d-flex flex-column flex-fill border border-${
          valid ? "shockblue" : "grayblue"
        }`}
      >
        <Form.Control
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { validity, value } = e.target;
            validity.valid && value ? setValid(true) : setValid(false);
            onChange(e);
          }}
          size="sm"
          required
          className="guilds-input w-100"
          {...nativeProps}
        />
      </div>
    </div>
  );
};

export default FormInput;
