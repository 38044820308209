import { Navigate, Outlet } from "react-router-dom";

export const Registered = ({ isRegistered = false }) => {
  return isRegistered ? (
    <Outlet />
  ) : (
    <Navigate replace to={`./complete-registration`} />
  );
};
export default Registered;
