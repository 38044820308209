import { Button, Form, Spinner } from "react-bootstrap";
import CountrySelector from "../components/CountrySelector";
import FormInput from "../components/FormInput";
import DefaultLayout from "../layout/Default";
import GenerateAddressModal from "../components/modals/GenerateAddress";
import { useState } from "react";
import { Profile, createDynamoProfile, createTempUser } from "../api/dynamoDb";
import { useSelector } from "react-redux";
import { selectors as uniscrowSelectors } from "../store/slices/uniscrow";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CopyButton } from "../components/CopyButton";
export const CompleteRegistration = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [isOver18, setIsOver18] = useState(false);
  const [postData, setPostData] = useState({} as Profile);
  const [addressData, setAddressData] = useState({
    street_address: "",
    postal_code: "",
    city: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useSelector(uniscrowSelectors.accessToken);
  const width=useSelector(uniscrowSelectors.clientWidth)
  return (
    <DefaultLayout svg="communicate">
      <GenerateAddressModal
        size="lg"
        callback={(addr: string) =>
          setPostData({ ...postData, blockchain_address: addr })
        }
        setShow={setShowModal}
        show={showModal}
      />
      <Form
        className="p-3 m-3"
        style={{ maxWidth: 500 }}
        onSubmit={(e) => {
          e.preventDefault();
          setIsLoading(true);
          const postDataWithAddress = {
            ...postData,
            home_address:
              addressData.street_address +
              addressData.postal_code +
              addressData.city,
          };
          createDynamoProfile(postDataWithAddress, accessToken).then(() => {
            createTempUser(
              user!.email!,
              postDataWithAddress["blockchain_address"]
            ).then((resp) => {
              console.log(resp);
              setIsLoading(false);
              navigate(0);
            });
          });
        }}
      >
        <fieldset disabled={isLoading}>
          <h1 className="text-darkblue fw-bold text-left">
            {" "}
            Complete your registration
          </h1>
          <div className="text-muted">
            We need you to help us with some information
          </div>
          <div className="divider my-3" />
          <div className="d-grid gap-2">
            <div
              style={{ maxWidth: 580,
              minWidth:width<520?0:410 }}
              className={`d-flex ${
                width < 520
                  ? "justify-content-center"
                  : "justify-content-between"
              } flex-wrap`}
            >
              <FormInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPostData({ ...postData, first_name: e.target.value });
                }}
                nativeProps={{
                  autoComplete: "given-name",
                  id: "firstname",
                  placeholder: "",
                  title: "First name",
                }}
                width={200}
              />
              <FormInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPostData({ ...postData, family_name: e.target.value });
                }}
                nativeProps={{
                  autoComplete: "family-name",
                  id: "lastname",
                  placeholder: "",
                  title: "Last name",
                }}
                width={200}
              />
              <CountrySelector
                nativeProps={{
                  id: "country",
                  autoComplete: "country",
                  title: "Country of residence",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPostData({
                    ...postData,
                    country_of_residence: e.target.value,
                  });
                }}
                width={200}
              />
              <FormInput
                nativeProps={{
                  id: "street-address",
                  title: "Street address",
                  autoComplete: "street-address",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressData({
                    ...addressData,
                    street_address: e.target.value,
                  });
                }}
                width={200}
              />
              <FormInput
                nativeProps={{
                  id: "postal-code",
                  title: "Postal code",
                  autoComplete: "postal-code",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressData({
                    ...addressData,
                    postal_code: e.target.value,
                  });
                }}
                width={200}
              />
              <FormInput
                nativeProps={{
                  id: "city",
                  title: "City",
                  autoComplete: "address-level2",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressData({ ...addressData, city: e.target.value });
                }}
                width={200}
              />
              <FormInput
                nativeProps={{
                  id: "IBAN",
                  title: "IBAN",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPostData({ ...postData, iban: e.target.value });
                }}
                width={width<510?200:"100%"}
              />
              <Form.Check
                required
                className="mt-4"
                title="Age verification"
                onChange={(e) => {
                  setIsOver18(e.target.checked);
                }}
                label="I declare that I am 18 years of age or over"
              />
            </div>

            <div className="divider my-1" />
            <Button
              className="my-2"
              variant="transparent"
              onClick={() => setShowModal(true)}
            >
              <small>
                GENERATE WALLET <i className="bi bi-arrow-clockwise" />
              </small>
            </Button>
            <div className="d-flex justify-content-around gap-2 align-items-center">
              <small>
                {postData.blockchain_address ? (
                  <span>
                    Your address:
                    <CopyButton longString={postData.blockchain_address}>
                      {shorten(postData.blockchain_address)}
                    </CopyButton>
                  </span>
                ) : (
                  <small>
                    Click on the button above to generate your ETH address
                  </small>
                )}
              </small>
            </div>
          </div>
          <div className="divider my-3" />
          <div
            className="d-grid"
            title={
              isOver18
                ? postData.blockchain_address
                  ? undefined
                  : "You need to generate an ETH address to continue"
                : "You need to be at least 18 years old to proceed"
            }
          >
            <Button
              className="p-2 align-items-center justify-content-center gap-3 d-flex"
              type="submit"
              disabled={!postData.blockchain_address || !isOver18}
            >
              {isLoading ? (
                <span className="d-flex align-items-center gap-2">
                  <Spinner animation="border" variant="grayblue" size="sm" />
                  Sending data, please wait..{" "}
                </span>
              ) : (
                <span>CONFIRM</span>
              )}
            </Button>
          </div>
        </fieldset>
      </Form>
    </DefaultLayout>
  );
};

function shorten(tokenAddr: string) {
  return (
    tokenAddr.slice(0, 5) +
    "..." +
    tokenAddr.slice(tokenAddr.length - 5, tokenAddr.length - 1)
  );
}
export default CompleteRegistration;
