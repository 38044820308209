export const Logo = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  return (
    <a href="https://www.uniscrow.com/" target="_blank" rel="noreferrer">
      <img
        className="m-3"
        src="/images/uniscrow_logo_long.png"
        alt="uniscrow logo"
        role="button"
        {...props}
      />
    </a>
  );
};
export default Logo;
