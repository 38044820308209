import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  accessToken:"",
  accessTokenRequested:false,
  clientWidth:window.innerWidth,
};
export const uniscrowSlice = createSlice({
  name: "uniscrow",
  initialState,
  reducers: {
    setAccessToken:(state,action)=>{
      state.accessToken=action.payload;
    },
    setAccessTokenRequested:(state,action)=>{
      state.accessTokenRequested=action.payload;
    },
    setClientWidth:(state,action)=>{
      state.clientWidth=action.payload;
    }
  },
});

export const selectors = {
  accessToken:(state:any)=>state.uniscrow.accessToken,
  accessTokenRequested:(state:any)=>state.uniscrow.accessTokenRequested,
  clientWidth:(state:any)=>state.uniscrow.clientWidth
};

export const { actions, reducer } = uniscrowSlice;
