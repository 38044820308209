import { Button } from "react-bootstrap";
import DefaultLayout from "../layout/Default";
import { useDispatch } from "react-redux";
import { actions as uniscrowActions } from "../store/slices/uniscrow";
type Props={
  isAuthenticated:boolean;
   loginWithRedirect:Function;
   logout:Function;
   user:any;
}
export const Login = ({ isAuthenticated, loginWithRedirect, logout, user }:Props) => {
  const dispatch = useDispatch();
  let path = window.location.pathname.split("/");
  path.splice(-1);
  return (
    <DefaultLayout svg="payment">
      <div className="p-3 m-3" style={{ maxWidth: 350 }}>
        <h1 className="text-darkblue fw-bold text-left"> Log in</h1>
        <div style={{ minWidth: 280 }}>
          Click the button below and pick your preferred login option.
        </div>
        <div className="divider my-5" />
        <div className="d-grid">
          {isAuthenticated && <span className="text-muted"> You are already authenticated as <b>{user?.email}</b></span>}
          <Button
            className="p-2 align-items-center justify-content-center gap-3 d-flex"
            type="submit"
            disabled={isAuthenticated}
            onClick={() =>
              loginWithRedirect({
                screen_hint: "login",
                appState: { targetUrl: path.join("/") },
              })
            }
          >
            LOG IN
            <img height={30} src="/svg/auth0.svg" alt="auth0 logo" />
          </Button>
        </div>
        {isAuthenticated && (
          <div className="d-grid pt-3">
            <Button
              className="p-2 align-items-center justify-content-center gap-3 d-flex"
              type="submit"
              onClick={() => {
                logout({ returnTo: window.location.href });
                dispatch(uniscrowActions.setAccessTokenRequested(false));
                dispatch(uniscrowActions.setAccessToken(""));
              }}
            >
              LOG OUT
              <img height={30} src="/svg/auth0.svg" alt="auth0 logo" />
            </Button>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Login;
